import React, { useState, useRef } from "react";
import { Box, FormControl, FormLabel, Input } from "@chakra-ui/react";

function CustomFileInput({ label, id, placeholder, name }) {
  const [selectedFileName, setSelectedFileName] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file ? file.name : "");
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Box onClick={handleBrowseClick}>
        <Input
          type="file"
          id={id}
          name={name}
          accept="image/*"
          onChange={handleFileChange}
          display="none"
          ref={fileInputRef}
        />
        <Input
          type="text"
          value={selectedFileName}
          readOnly
          placeholder={placeholder}
          cursor="pointer"
        />
      </Box>
    </FormControl>
  );
}

export default CustomFileInput;
