async function klaviyoCreateEvent(payload) {
  const endpoint = "https://a.klaviyo.com/client/events/?company_id=Y4euYT";
  await fetch(endpoint, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      revision: "2023-12-15",
    },
    body: JSON.stringify(payload),
  }).catch((err) => console.error(err));
}

function klaviyoAddProperties(form) {
  const properties = {};
  form.forEach((value, key) => {
    if (typeof value === "object") {
      properties[key] = JSON.stringify(value);
      return;
    }
    properties[key] = value;
  });
  return properties;
}

export async function klaviyoTrackEvent(form, event) {
  const payload = {
    data: {
      type: "event",
      attributes: {
        properties: klaviyoAddProperties(form),
        time: new Date().toISOString(),
        metric: {
          data: {
            type: "metric",
            attributes: {
              name: event,
            },
          },
        },
        profile: {
          data: {
            type: "profile",
            attributes: {
              email: form.get("email"),
              first_name: getFieldValue(form, "firstname", "firstName"),
              last_name: getFieldValue(form, "lastname", "lastName"),
            },
          },
        },
      },
    },
  };

  await klaviyoCreateEvent(payload);
}

function getFieldValue(form, first, second) {
  return form.get(first) ?? form.get(second) ?? "N/C";
}
