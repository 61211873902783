import React, { useState } from "react";
import { Divider } from "@chakra-ui/react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button,
  VStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import CustomFileInput from "../inputs/CustomFileInput";
import { useMediaQuery } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { klaviyoTrackEvent } from "../../lib/klaviyo";

const FormulaireSAV = () => {
  const [productSections, setProductSections] = useState(1);
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const addProductSection = () => {
    setProductSections(productSections + 1);
  };

  const removeProductSection = () => {
    if (productSections > 1) {
      setProductSections(productSections - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint = "https://formspree.io/f/xzblkgav";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        await klaviyoTrackEvent(new FormData(e.target), "Submitted SAV B2B");
        setIsFormSubmitted(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { sav_form } = useIntl().messages;

  console.log(sav_form.product_category);

  return (
    <>
      <Box p={4} className="container">
        <h1>{sav_form.form_title}</h1>
        <p className="text-center">{sav_form.form_intro}</p>
        {isFormSubmitted ? (
          <div>
            <h2 style={{ textAlign: "center" }}>
              {sav_form.submission_success.title}
            </h2>
            <p className="text-center">{sav_form.submission_success.message}</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <h2>{sav_form.client_informations}</h2>
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel htmlFor="storeCode">
                  {sav_form.store_code.label}
                </FormLabel>
                <Input
                  type="text"
                  id="storeCode"
                  name={sav_form.store_code.label}
                  placeholder={sav_form.store_code.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="storeName">
                  {sav_form.store_name.label}
                </FormLabel>
                <Input
                  type="text"
                  id="storeName"
                  name={sav_form.store_name.label}
                  placeholder={sav_form.store_name.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">{sav_form.email.label}</FormLabel>
                <Input
                  type="email"
                  id="email"
                  name={sav_form.email.label}
                  placeholder={sav_form.email.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="phone">{sav_form.phone.label}</FormLabel>
                <Input
                  type="tel"
                  id="phone"
                  name={sav_form.phone.label}
                  placeholder={sav_form.phone.placeholder}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="returnReference">
                  {sav_form.return_reference.label}
                </FormLabel>
                <Input
                  type="text"
                  id="returnReference"
                  name={sav_form.return_reference.label}
                  placeholder={sav_form.return_reference.placeholder}
                />
              </FormControl>
            </VStack>

            <Divider mt={6} />

            <h2>{sav_form.product_details}</h2>

            {[...Array(productSections)].map((_, index) => (
              <div key={index}>
                {index > 0 && <Divider mt={6} mb={6} />}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>
                    {sav_form.product_section} {index + 1}
                  </h3>
                  {productSections > 1 && (
                    <Button
                      type="button"
                      variant="secondary"
                      height="auto"
                      mb="4"
                      p="4"
                      onClick={removeProductSection}
                    >
                      {sav_form.remove_product}
                    </Button>
                  )}
                </div>
                <VStack spacing={5}>
                  <FormControl isRequired>
                    <FormLabel htmlFor={`productCategory_${index + 1}`}>
                      {sav_form.product_category.label}
                    </FormLabel>
                    <Select
                      id={`productCategory_${index + 1}`}
                      name={`${sav_form.product_category.label} ${index + 1}`}
                      placeholder={sav_form.product_category.placeholder}
                    >
                      {sav_form.product_category.options.map((option, i) => (
                        <option key={i} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <Grid
                    templateColumns={{
                      base: "1fr",
                      sm: "repeat(2, 1fr)",
                    }}
                    gap={5}
                    width="100%"
                  >
                    <GridItem colSpan={1}>
                      <FormControl isRequired>
                        <FormLabel htmlFor={`productReference_${index + 1}`}>
                          {sav_form.product_reference.label}
                        </FormLabel>
                        <Input
                          type="text"
                          id={`productReference_${index + 1}`}
                          name={`${sav_form.product_reference.label} ${
                            index + 1
                          }`}
                          placeholder={sav_form.product_reference.placeholder}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormControl isRequired>
                        <FormLabel htmlFor={`productLabel_${index + 1}`}>
                          {sav_form.product_label.label}
                        </FormLabel>
                        <Input
                          type="text"
                          id={`productLabel_${index + 1}`}
                          name={`${sav_form.product_label.label} ${index + 1}`}
                          placeholder={sav_form.product_label.placeholder}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormControl isRequired>
                        <FormLabel htmlFor={`productSize_${index + 1}`}>
                          {sav_form.product_size.label}
                        </FormLabel>
                        <Input
                          type="text"
                          id={`productSize_${index + 1}`}
                          name={`${sav_form.product_size.label} ${index + 1}`}
                          placeholder={sav_form.product_size.placeholder}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormControl isRequired>
                        <FormLabel htmlFor={`productColor_${index + 1}`}>
                          {sav_form.product_color.label}
                        </FormLabel>
                        <Input
                          type="text"
                          id={`productColor_${index + 1}`}
                          name={`${sav_form.product_color.label} ${index + 1}`}
                          placeholder={sav_form.product_color.placeholder}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <FormControl isRequired>
                    <FormLabel htmlFor={`purchaseDate_${index + 1}`}>
                      {sav_form.purchase_date}
                    </FormLabel>
                    <Input
                      type="date"
                      id={`purchaseDate_${index + 1}`}
                      name={`${sav_form.purchase_date} ${index + 1}`}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor={`productPhoto_${index + 1}`}>
                      {sav_form.product_photo.label}
                    </FormLabel>
                    <CustomFileInput
                      id={`productPhoto_${index + 1}`}
                      name={`${sav_form.product_photo.label} ${index + 1}`}
                      placeholder={sav_form.product_photo.placeholder}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor={`defectPhoto_${index + 1}`}>
                      {sav_form.defect_photo.label}
                    </FormLabel>
                    <CustomFileInput
                      id={`defectPhoto_${index + 1}`}
                      name={`${sav_form.defect_photo.label} ${index + 1}`}
                      placeholder={sav_form.defect_photo.placeholder}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor={`additionalPhoto_${index + 1}`}>
                      {sav_form.additional_photo.label}
                    </FormLabel>
                    <CustomFileInput
                      id={`additionalPhoto_${index + 1}`}
                      name={`${sav_form.additional_photo.label} ${index + 1}`}
                      placeholder={sav_form.additional_photo.placeholder}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor={`isNew_${index + 1}`}>
                      {sav_form.new_product.label}
                    </FormLabel>
                    <Select
                      id={`isNew_${index + 1}`}
                      name={`${sav_form.new_product.label} ${index + 1}`}
                      placeholder={sav_form.new_product.placeholder}
                    >
                      <option value="Oui">{sav_form.yes}</option>
                      <option value="Non">{sav_form.no}</option>
                    </Select>
                  </FormControl>

                  <Grid
                    templateColumns={{
                      base: "1fr",
                    }}
                    gap={5}
                    width="100%"
                    justifyItems={isMobile ? "stretch" : "end"}
                  >
                    {index === productSections - 1 && (
                      <Button
                        type="button"
                        height="auto"
                        variant="primary"
                        mb="4"
                        p="4"
                        onClick={addProductSection}
                        width={isMobile ? "100%" : "auto"}
                      >
                        {sav_form.add_product}
                      </Button>
                    )}
                  </Grid>
                </VStack>
              </div>
            ))}

            <Divider mt={3} mb={6} />

            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel htmlFor="problemDescription">
                  {sav_form.problem_description.label}
                </FormLabel>
                <Textarea
                  id="problemDescription"
                  name={sav_form.problem_description.label}
                  placeholder={sav_form.problem_description.placeholder}
                  size="sm"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="comments">
                  {sav_form.comments.label}
                </FormLabel>
                <Textarea
                  id="comments"
                  name={sav_form.comments.label}
                  placeholder={sav_form.comments.placeholder}
                  size="sm"
                />
              </FormControl>
            </VStack>

            <Grid
              templateColumns={{
                base: "1fr",
              }}
              gap={5}
              justifyItems={isMobile ? "stretch" : "end"}
            >
              <Button
                type="submit"
                variant="primary"
                height="auto"
                mt="4"
                mb="4"
                p="4"
                width={isMobile ? "100%" : "auto"}
              >
                {sav_form.submit}
              </Button>
            </Grid>
          </form>
        )}
      </Box>
    </>
  );
};

export default FormulaireSAV;
