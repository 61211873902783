import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ClientReclamation from "./component/forms/ClientReclamation.jsx";
import FormulaireSAV from "./component/forms/FormulaireSAV.jsx";
import ReclamationSAV from "./component/forms/ReclamationSAV.jsx";
import CharteGarantie from "./component/forms/CharteGarantie.jsx";
import ContactForm from "./component/forms/ContactForm.jsx";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/:locale/reclamation-client"
          component={ClientReclamation}
        />
        <Route path="/:locale/sav" component={ReclamationSAV} />
        <Route path="/:locale/sav-b2b" component={FormulaireSAV} />
        <Route
          path="/:locale/lifetimerepairwarranty"
          component={CharteGarantie}
        />
        <Route path="/:locale/contact" component={ContactForm} />
      </Switch>
    </Router>
  );
};

export default Routes;
