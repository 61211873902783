import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Textarea,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { klaviyoTrackEvent } from "../../lib/klaviyo";

const ContactForm = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint = "https://formspree.io/f/moqgekdg";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        await klaviyoTrackEvent(new FormData(e.target), "Submitted Contact");
        setIsFormSubmitted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { contact } = useIntl().messages;

  return (
    <>
      <Box p="4" className="container">
        <h1>{contact.title}</h1>
        <p className="text-center">{contact.intro}</p>
        {isFormSubmitted ? (
          <div>
            <h2 style={{ textAlign: "center" }}>
              {contact.submission_success.title}
            </h2>
            <p className="text-center">{contact.submission_success.message}</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel htmlFor="object">{contact.object.label}</FormLabel>
                <Select id="object" name="object">
                  {contact.object.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">{contact.email.label}</FormLabel>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={contact.email.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="country">{contact.country.label}</FormLabel>
                <Input
                  type="text"
                  id="country"
                  name="country"
                  placeholder={contact.country.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="firstname">
                  {contact.firstname.label}
                </FormLabel>
                <Input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder={contact.firstname.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="lastname">
                  {contact.lastname.label}
                </FormLabel>
                <Input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder={contact.lastname.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="message">{contact.message.label}</FormLabel>
                <Textarea
                  id="message"
                  name="message"
                  placeholder={contact.message.placeholder}
                  size="sm"
                />
              </FormControl>
              <FormControl isRequired>
                <Checkbox colorScheme="gray" alignItems="start" spacing={5}>
                  {contact.validation.text}{" "}
                  <a
                    href={contact.validation.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contact.validation.link.text}
                  </a>
                </Checkbox>
              </FormControl>
            </VStack>
            <Grid
              templateColumns={{
                base: "1fr",
              }}
              gap={5}
              justifyItems={isMobile ? "stretch" : "end"}
            >
              <Button
                type="submit"
                height="auto"
                mt="4"
                mb="4"
                p="4"
                variant="primary"
                width={isMobile ? "100%" : "auto"}
              >
                {contact.submit}
              </Button>
            </Grid>
          </form>
        )}
      </Box>
    </>
  );
};

export default ContactForm;
