import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button,
  VStack,
  Grid,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import CustomFileInput from "../inputs/CustomFileInput";
import { Divider } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { klaviyoTrackEvent } from "../../lib/klaviyo";

const ReclamationSAV = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [selectedPurchaseType, setSelectedPurchaseType] = useState("inStore");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint = "https://formspree.io/f/xvojvrld";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        await klaviyoTrackEvent(new FormData(e.target), "Submitted SAV");
        setIsFormSubmitted(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePurchaseTypeChange = (event) => {
    setSelectedPurchaseType(event.target.value);
  };

  const { sav_reclamation } = useIntl().messages;

  return (
    <>
      <Box p={4} className="container">
        <h1>{sav_reclamation.form_title}</h1>
        <p className="text-center">{sav_reclamation.form_intro}</p>
        {isFormSubmitted ? (
          <div>
            <h2 style={{ textAlign: "center" }}>
              {sav_reclamation.submission_success.title}
            </h2>
            <p className="text-center">
              {sav_reclamation.submission_success.message}
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <h2>{sav_reclamation.details}</h2>
            <VStack spacing={5}>
              <Grid
                templateColumns={{
                  base: "1fr",
                  sm: "repeat(2, 1fr)",
                }}
                gap={5}
                width="100%"
              >
                <FormControl isRequired>
                  <FormLabel htmlFor="firstName">
                    {sav_reclamation.firstName.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder={sav_reclamation.firstName.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="lastName">
                    {sav_reclamation.lastName.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder={sav_reclamation.lastName.placeholder}
                  />
                </FormControl>
              </Grid>
              <FormControl isRequired>
                <FormLabel htmlFor="postalAddress">
                  {sav_reclamation.address.label}
                </FormLabel>
                <Input
                  type="text"
                  id="postalAddress"
                  name="postalAddress"
                  placeholder={sav_reclamation.address.placeholder}
                />
              </FormControl>
              <Grid
                templateColumns={{
                  base: "1fr",
                  sm: "repeat(2, 1fr)",
                }}
                gap={5}
                width="100%"
              >
                <FormControl isRequired>
                  <FormLabel htmlFor="city">
                    {sav_reclamation.city.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    placeholder={sav_reclamation.city.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="postalCode">
                    {sav_reclamation.postal_code.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    placeholder={sav_reclamation.postal_code.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="email">
                    {sav_reclamation.email.label}
                  </FormLabel>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={sav_reclamation.email.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="phone">
                    {sav_reclamation.phone.label}
                  </FormLabel>
                  <Input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder={sav_reclamation.phone.placeholder}
                  />
                </FormControl>
              </Grid>
            </VStack>

            <Divider mt={6} />

            <h2>{sav_reclamation.purchase_details}</h2>
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel htmlFor="purchaseType">
                  {sav_reclamation.purchase_type}
                </FormLabel>
                <Select
                  id="purchaseType"
                  name="purchaseType"
                  onChange={handlePurchaseTypeChange}
                >
                  <option value="inStore">
                    {sav_reclamation.in_store_purchase}
                  </option>
                  <option value="online">
                    {sav_reclamation.online_purchase}
                  </option>
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="proofOfPurchase">
                  {sav_reclamation.proof_of_purchase.label}
                </FormLabel>
                <CustomFileInput
                  id="proofOfPurchase"
                  name="proofOfPurchase"
                  placeholder={sav_reclamation.proof_of_purchase.placeholder}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="purchaseDate">
                  {sav_reclamation.purchase_date.label}
                </FormLabel>
                <Input
                  type="date"
                  id="purchaseDate"
                  name={sav_reclamation.purchase_date.placeholder}
                />
              </FormControl>
              {selectedPurchaseType === "inStore" && (
                <>
                  <FormControl isRequired>
                    <FormLabel htmlFor="storeName">
                      {sav_reclamation.store.name.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="storeName"
                      name="storeName"
                      placeholder={sav_reclamation.store.name.placeholder}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="storePostalCode">
                      {sav_reclamation.store.postal_code.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="storePostalCode"
                      name="storePostalCode"
                      placeholder={
                        sav_reclamation.store.postal_code.placeholder
                      }
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="storeCity">
                      {sav_reclamation.store.city.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="storeCity"
                      name="storeCity"
                      placeholder={sav_reclamation.store.city.placeholder}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="storeCountry">
                      {sav_reclamation.store.country.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="storeCountry"
                      name="storeCountry"
                      placeholder={sav_reclamation.store.country.placeholder}
                    />
                  </FormControl>
                </>
              )}
              {selectedPurchaseType === "online" && (
                <FormControl isRequired>
                  <FormLabel htmlFor="storeLink">
                    {sav_reclamation.store_link.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="storeLink"
                    name="storeLink"
                    placeholder={sav_reclamation.store_link.placeholder}
                  />
                </FormControl>
              )}
            </VStack>

            <Divider mt={6} />

            <h2>{sav_reclamation.product_details}</h2>
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel htmlFor="productName">
                  {sav_reclamation.product_name.label}
                </FormLabel>
                <Input
                  type="text"
                  id="productName"
                  name="productName"
                  placeholder={sav_reclamation.product_name.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="productSize">
                  {sav_reclamation.product_size.label}
                </FormLabel>
                <Input
                  type="text"
                  id="productSize"
                  name="productSize"
                  placeholder={sav_reclamation.product_size.placeholder}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="productColor">
                  {sav_reclamation.product_color.label}
                </FormLabel>
                <Input
                  type="text"
                  id="productColor"
                  name="productColor"
                  placeholder={sav_reclamation.product_color.placeholder}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="productPhoto">
                  {sav_reclamation.product_photo.label}
                </FormLabel>
                <CustomFileInput
                  id="productPhoto"
                  name="productPhoto"
                  placeholder={sav_reclamation.product_photo.placeholder}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="defectPhoto">
                  {sav_reclamation.defect.label}
                </FormLabel>
                <CustomFileInput
                  id="defectPhoto"
                  name="defectPhoto"
                  placeholder={sav_reclamation.defect.placeholder}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="problemDescription">
                  {sav_reclamation.problem_description.label}
                </FormLabel>
                <Textarea
                  id="problemDescription"
                  name="problemDescription"
                  placeholder={sav_reclamation.problem_description.placeholder}
                  size="sm"
                />
              </FormControl>
            </VStack>

            <Grid
              templateColumns={{
                base: "1fr",
              }}
              gap={5}
              justifyItems={isMobile ? "stretch" : "end"}
            >
              <Button
                type="submit"
                height="auto"
                mt="4"
                mb="4"
                p="4"
                variant="primary"
                width={isMobile ? "100%" : "auto"}
              >
                {sav_reclamation.submit}
              </Button>
            </Grid>
          </form>
        )}
      </Box>
    </>
  );
};

export default ReclamationSAV;
