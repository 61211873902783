import React, { useState } from "react";
import { Divider } from "@chakra-ui/react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import CustomFileInput from "../inputs/CustomFileInput";
import { useMediaQuery } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { klaviyoTrackEvent } from "../../lib/klaviyo";

const FormulaireReclamation = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint = "https://formspree.io/f/mwkdbajp";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        await klaviyoTrackEvent(
          new FormData(e.target),
          "Submitted Reclamation"
        );
        setIsFormSubmitted(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { client_reclamation } = useIntl().messages;

  return (
    <>
      <Box p="4" className="container">
        <h1>{client_reclamation.form_title}</h1>
        {isFormSubmitted ? (
          <div>
            <h2 style={{ textAlign: "center" }}>
              {client_reclamation.submission_success.title}
            </h2>
            <p className="text-center">
              {client_reclamation.submission_success.message}
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <h2>{client_reclamation.general}</h2>
            <VStack spacing={5}>
              <Grid
                templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }}
                gap={5}
                width="100%"
              >
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="numeroColis">
                      {client_reclamation.package_number.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="numeroColis"
                      name="numeroColis"
                      placeholder={
                        client_reclamation.package_number.placeholder
                      }
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="numeroBP">
                      {client_reclamation.bp_number.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="numeroBP"
                      name="numeroBP"
                      placeholder={client_reclamation.bp_number.placeholder}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="numeroFacture">
                      {client_reclamation.invoice_number.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="numeroFacture"
                      name="numeroFacture"
                      placeholder={
                        client_reclamation.invoice_number.placeholder
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="refCommande">
                      {client_reclamation.order_reference.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="refCommande"
                      name="refCommande"
                      placeholder={
                        client_reclamation.order_reference.placeholder
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>

              <FormControl isRequired>
                <FormLabel htmlFor="date">{client_reclamation.date}</FormLabel>
                <Input type="date" id="date" name="date" />
              </FormControl>
            </VStack>

            <Divider mt={6} />

            <h2>{client_reclamation.client_informations}</h2>
            <VStack spacing={5}>
              <Grid
                templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }}
                gap={5}
                width="100%"
              >
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="nomShop">
                      {client_reclamation.store_name.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="nomShop"
                      name="nomShop"
                      placeholder={client_reclamation.store_name.placeholder}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="email">
                      {client_reclamation.email.label}
                    </FormLabel>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      placeholder={client_reclamation.email.placeholder}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="telephone">
                      {client_reclamation.phone.label}
                    </FormLabel>
                    <Input
                      type="tel"
                      id="telephone"
                      name="telephone"
                      placeholder={client_reclamation.phone.placeholder}
                      maxLength={10}
                      pattern="[0-9]*"
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="codeClient">
                      {client_reclamation.client_code.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="codeClient"
                      name="codeClient"
                      placeholder={client_reclamation.client_code.placeholder}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <FormControl isRequired>
                <FormLabel htmlFor="motifReclamation">
                  {client_reclamation.complaint_reason.label}
                </FormLabel>
                <Textarea
                  id="motifReclamation"
                  name="motifReclamation"
                  placeholder={client_reclamation.complaint_reason.placeholder}
                  size="sm"
                />
              </FormControl>
            </VStack>

            <Divider mt={6} />

            <h2>{client_reclamation.complaint_details}</h2>
            <VStack spacing={5}>
              <Grid
                templateColumns={{
                  base: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap={5}
                width="100%"
              >
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="article">
                      {client_reclamation.article.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="article"
                      name="article"
                      placeholder={client_reclamation.article.placeholder}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="nomArticle">
                      {client_reclamation.article.name.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="nomArticle"
                      name="nomArticle"
                      placeholder={client_reclamation.article.name.placeholder}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="coloris">
                      {client_reclamation.article.color.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="coloris"
                      name="coloris"
                      placeholder={client_reclamation.article.color.placeholder}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="taille">
                      {client_reclamation.article.size.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="taille"
                      name="taille"
                      placeholder={client_reclamation.article.size.placeholder}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="eanCode">
                      {client_reclamation.article.ean_code.label}
                    </FormLabel>
                    <Input
                      type="text"
                      id="eanCode"
                      name="eanCode"
                      placeholder={
                        client_reclamation.article.ean_code.placeholder
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="quantite">
                      {client_reclamation.article.quantity.label}
                    </FormLabel>
                    <Input
                      type="number"
                      id="quantite"
                      name="quantite"
                      placeholder={
                        client_reclamation.article.quantity.placeholder
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <FormControl>
                <FormLabel htmlFor="commentaire" isRequired>
                  {client_reclamation.comments.label}
                </FormLabel>
                <Input
                  type="text"
                  id="commentaire"
                  name="commentaire"
                  placeholder={client_reclamation.comments.placeholder}
                />
              </FormControl>
            </VStack>

            <Divider mt={6} />

            <h2>{client_reclamation.package_photo.title}</h2>
            <VStack spacing={5}>
              <FormControl isRequired>
                <FormLabel htmlFor="photoColis">
                  {client_reclamation.package_photo.label}
                </FormLabel>
                <CustomFileInput
                  id="photoColis"
                  name="photoColis"
                  placeholder={client_reclamation.package_photo.placeholder}
                />
              </FormControl>
            </VStack>

            <Grid
              templateColumns={{
                base: "1fr",
              }}
              gap={5}
              justifyItems={isMobile ? "stretch" : "end"}
            >
              <Button
                type="submit"
                height="auto"
                variant="primary"
                mt="4"
                mb="4"
                p="4"
                width={isMobile ? "100%" : "auto"}
              >
                {client_reclamation.submit}
              </Button>
            </Grid>
          </form>
        )}
      </Box>
    </>
  );
};

export default FormulaireReclamation;
