import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Unica Regular, Roboto, sans-serif",
    heading: "Unica Regular, Roboto, sans-serif",
  },
  colors: {
    gray: {
      50: "#F6F6F6",
      200: "#D8D8D8",
      600: "#6A6A6A",
    },
  },
  components: {
    Button: {
      baseStyle: {
        width: "fit-content",
        minW: "12.5rem!important",
        minWidth: "12.5rem!important",
        borderRadius: "0",
        fontWeight: "bold",
        borderColor: "transparent",
        borderWidth: "2px",
      },
      variants: {
        primary: {
          color: "white",
          backgroundColor: "black",
          _hover: {
            borderColor: "black",
            color: "black",
            backgroundColor: "white",
          },
        },
        secondary: {
          borderColor: "black",
          color: "black",
          backgroundColor: "white",
          _hover: {
            color: "white",
            backgroundColor: "black",
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          height: "3rem",
          backgroundColor: "gray.50!important",
          borderRadius: "0",
          fontSize: ".875rem",
          lineHeight: "1.25rem",
          padding: ".75rem 1rem",
          _placeholder: {
            color: "gray.600",
          },
          _focus: {
            borderColor: "gray.200!important",
            boxShadow: "0 0 0 1px #F6F6F6!important",
          },
          _focusVisible: {
            borderColor: "gray.200!important",
            boxShadow: "0 0 0 1px #F6F6F6!important",
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        backgroundColor: "gray.50!important",
        borderRadius: "0",
        _placeholder: {
          color: "gray.600",
        },
        _focus: {
          borderColor: "gray.200!important",
          boxShadow: "0 0 0 1px #F6F6F6!important",
        },
        _focusVisible: {
          borderColor: "gray.200!important",
          boxShadow: "0 0 0 1px #F6F6F6!important",
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          fontSize: ".875rem",
          lineHeight: "1.25rem",
          backgroundColor: "gray.50!important",
          borderRadius: "0",
          _placeholder: {
            color: "gray.600",
          },
          _focus: {
            borderColor: "gray.200!important",
            boxShadow: "0 0 0 1px #F6F6F6!important",
          },
          _focusVisible: {
            borderColor: "gray.200!important",
            boxShadow: "0 0 0 1px #F6F6F6!important",
          },
        },
      },
    },
    FormLabel: {
      baseStyle: { marginBottom: ".25rem" },
    },
  },
  styles: {
    global: {
      ".container": {
        maxWidth: "750px",
        margin: "0 auto",
      },
      ".chakra-form__required-indicator": {
        color: "gray.600!important",
      },
      nav: {
        display: "flex",
        justifyContent: "center",
        gap: "1.25rem",
        marginTop: "2.5rem",
        textAlign: "center",
      },
      h1: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "clamp(1.5rem, 1.086rem + 1.77vw, 2.5rem)",
        lineHeight: "calc(40/40)",
        paddingTop: "3.5rem",
        paddingBottom: "1.5rem",
      },
      h2: {
        fontSize: "1.25rem",
        fontWeight: "bold",
        lineHeight: "1.75rem",
        margin: "1rem 0",
      },
      h3: {
        fontSize: "1rem",
        fontWeight: "bold",
        lineHeight: "1.5rem",
        margin: "1rem 0",
      },
      p: {
        color: "gray.600",
        paddingBottom: "1.5rem",
      },
      ul: {
        color: "gray.600",
        paddingBottom: "1.5rem",
      },
    },
  },
});

export default theme;
