import * as React from "react";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import { Divider } from "@chakra-ui/react";
import translations from "./translations/translations.json";
import Routes from "./routes.js";
import logo from "./main-logo.svg";
import theme from "./theme/index";

function App() {
  const locale = window.location.pathname.split("/")[1];

  return (
    <Router forceRefresh={true}>
      <div className="container">
        <a href="https://www.picture-organic-clothing.com/">
          <img src={logo} alt="Logo" className="main-logo" />
        </a>
      </div>
      <ChakraProvider theme={theme}>
        <IntlProvider messages={translations[locale]} locale={locale}>
          <CSSReset />
          <Routes />
          <Divider mt={6} />
          <div className="footer container">
            <a href="https://www.picture-organic-clothing.com/fr/mentions-legales/legalinformation.html">
              Mentions légales
            </a>

            <a href="https://www.picture-organic-clothing.com/fr/politique-de-confidentialite/privacypolicy.html">
              Politique de confidentialité
            </a>

            <a href="https://www.picture-organic-clothing.com/on/demandware.store/Sites-PictureOrganicClothing-Site/fr/Page-PlanSite">
              Plan du site
            </a>
          </div>
        </IntlProvider>
      </ChakraProvider>
    </Router>
  );
}
export default App;
