import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  VStack,
  Grid,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { klaviyoTrackEvent } from "../../lib/klaviyo";

const ReclamationSAV = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { warranty } = useIntl().messages;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint = "https://formspree.io/f/xleqjewl";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        await klaviyoTrackEvent(
          new FormData(e.target),
          "Subscribed Lifetime Repair Warranty"
        );
        setIsFormSubmitted(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box p={4} className="container">
        <h1>{warranty.title}</h1>
        <p className="text-center">{warranty.intro}</p>
        {isFormSubmitted ? (
          <div>
            <h2 style={{ textAlign: "center" }}>
              {warranty.submission_success.title}
            </h2>
            <p className="text-center">{warranty.submission_success.message}</p>
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <VStack spacing={5}>
                <FormControl isRequired>
                  <FormLabel htmlFor="email">{warranty.email.label}</FormLabel>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    placeholder={warranty.email.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="gender">
                    {warranty.gender.label}
                  </FormLabel>
                  <Select
                    id="gender"
                    name="gender"
                    placeholder={warranty.gender.placeholder}
                  >
                    {warranty.gender.options.map((gender, index) => (
                      <option key={index} value={gender}>
                        {gender}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="firstname">
                    {warranty.firstname.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder={warranty.firstname.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="lastname">
                    {warranty.lastname.label}
                  </FormLabel>
                  <Input
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder={warranty.lastname.placeholder}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="country">
                    {warranty.country.label}
                  </FormLabel>
                  <Select
                    id="country"
                    name="country"
                    placeholder={warranty.country.placeholder}
                  >
                    {warranty.country.options.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="typeProduct">
                    {warranty.product_type.label}
                  </FormLabel>
                  <Select
                    id="typeProduct"
                    name="typeProduct"
                    placeholder={warranty.product_type.placeholder}
                  >
                    {warranty.product_type.options.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="purchaseDate">
                    {warranty.purchase_date}
                  </FormLabel>
                  <Input type="date" id="purchaseDate" name="purchaseDate" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="placePurchase">
                    {warranty.purchase_place.label}
                  </FormLabel>
                  <Select
                    id="placePurchase"
                    name="placePurchase"
                    placeholder={warranty.purchase_place.placeholder}
                  >
                    {warranty.purchase_place.options.map((place, index) => (
                      <option key={index} value={place}>
                        {place}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>

              <Grid
                templateColumns={{
                  base: "1fr",
                }}
                gap={5}
                justifyItems={isMobile ? "stretch" : "end"}
              >
                <Button
                  type="submit"
                  height="auto"
                  mt="4"
                  mb="4"
                  p="4"
                  variant="primary"
                  width={isMobile ? "100%" : "auto"}
                >
                  {warranty.submit}
                </Button>
              </Grid>

              <p className="small text-center">
                * {warranty.required_fields} 😊
              </p>
            </form>

            <h2>{warranty.policy.title}</h2>
            {warranty.policy.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <ul>
              {warranty.policy.list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p>
              <em>
                *{warranty.policy.note.text}
                <a
                  href={warranty.policy.note.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-link-type="external"
                  data-link-label="https://www.picture-organic-clothing.com/fr/service-apres-vente"
                >
                  {warranty.policy.note.link}
                </a>
              </em>
            </p>
            <p>{warranty.policy.paragraph_2}</p>
            <h2>{warranty.conditions.title}</h2>
            <p>
              À la suite de l’expiration du délai légal de garantie, et si votre
              produit fait partie de la liste ci-dessus des produits pris en
              charge, il vous sera demandé de remplir quelques conditions.
            </p>

            {warranty.conditions.list.map((item, index) => (
              <p key={index}>
                {index + 1}.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item}
              </p>
            ))}
            <p>
              <strong>{warranty.conditions.non_covered.title}</strong>
            </p>
            <ul>
              {warranty.conditions.non_covered.list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            {warranty.conditions.non_covered.paragraphs.map(
              (paragraph, index) => (
                <p key={index}>{paragraph}</p>
              )
            )}
            <p>
              <strong>{warranty.conditions.setup.title}</strong>
            </p>
            {warranty.conditions.setup.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <p>
              <strong>{warranty.conditions.shipment.title}</strong>
            </p>
            <p>{warranty.conditions.shipment.intro}</p>
            <p>
              <a
                href={warranty.conditions.shipment.link}
                target="_blank"
                rel="noreferrer"
              >
                {warranty.conditions.shipment.link}
              </a>
            </p>
            <p>{warranty.conditions.shipment.subtitle}</p>
            <ul>
              <li>
                {warranty.conditions.shipment.list[0].text}
                <em>{warranty.conditions.shipment.list[0].note}</em>
              </li>
              {warranty.conditions.shipment.list[1].map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            {warranty.conditions.shipment.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        )}
      </Box>
    </>
  );
};

export default ReclamationSAV;
